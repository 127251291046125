<template>
	<!--
		@dragover.prevent
		@drop.prevent="!disabled ? (() => { updateAudios($event) })() : null"
	-->
	<div class="audio-drop-zone">
		<div
			v-ripple="!audios.length && !disabled"
			class="audio-container"
			@click="!audios.length && !disabled ? $emit('click') : null"
		>
			<div v-for="(audio, index) in audios" :key="index">
				<div class="audio-container__item d-flex justify-center align-center">
					<div
						class="d-flex justify-center align-center flex-grow-1"
						@click="previewAudioUrl = BYTE_RANGE_BASE_URL + `/${audio.id}`"
					>
						<v-icon size="24" class="me-2">mdi-volume-high</v-icon>
						<span class="d-block text-body-1">
							{{audio.name}}
						</span>
						<v-spacer/>
					</div>
					<v-btn
						v-if="!disabled"
						icon
						@click="
							$emit('remove-audio', audio.url);
							audios.splice(index, 1);
							$emit('input', audios);
							previewAudioUrl = null;
						"
					>
						<v-icon color="red lighten-1">mdi-delete</v-icon>
					</v-btn>
				</div>
			</div>
		
			<!-- labels and buttons -->
			<div v-if="!audios.length && !loading" class="d-flex justify-center align-center">
				<v-icon key="icon" size="36" class="me-1">mdi-volume-high</v-icon>
				<span class="d-block text-body-1 font-weight-bold">{{$t('upload-audios')}}</span>
			</div>
			<v-btn
				v-else-if="audios.length && !disabled"
				height="24"
				width="100%"
				class="rounded-medium"
				text
				@click="$emit('click')"
			>
				<v-icon key="add" class="me-1">mdi-plus-circle-outline</v-icon>
				{{$t('upload-audios')}}
			</v-btn>
			<v-progress-linear v-if="loading" indeterminate/>
		</div>
		<span class="red--text text--lighten-1">{{error}}</span>
		
		<!-- player -->
		<audio-player
			v-if="previewAudioUrl"
			ref="audioPlayer"
			:src="previewAudioUrl"
			main-controls
			class="mt-2"
		/>
	</div>
</template>

<script>
import { BYTE_RANGE_BASE_URL } from '@/helpers/constants';
export default {
	name: 'BeeAudioZone',
	props: {
		disabled: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		'error-message': { type: String, default: 'only mp4 audios are allowed' },
		'no-drop-replace': { type: Boolean, default: false },
		value: { type: Array, default: () => [] }
	},

	data() {
		return {
			BYTE_RANGE_BASE_URL,

			previewAudioUrl: null,
			error: null,
			audios: [],
		}
	},

	watch: {
		value: {
			handler (val) {
				if (!val || val.length === 0) {
					this.previewAudioUrl = null;
					this.audios = [];
				}
				this.audios = val.map(c => {
					if (typeof c === 'string') {
						return { name: c, url: c }
					} else {
						return { name: c.name, url: c.url, ...c }
					}
				})
			},
			immediate: true
		}
	},

	methods: {
		updateAudios(event) {
			const files = event.dataTransfer.files;
			this.setAudio(files);
		},

		setAudio(files) {
			const arrayFiles = [];
			if (this.isAdd || this.noDropReplace) {
				arrayFiles.push(...this.audios);
			}
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				if (!/audio\/mp4/g.test(file.type)) {
					this.error = this.errorMessage;
					return null;
				}
				arrayFiles.push({ name: file.name, url: URL.createObjectURL(file) });
			}
			this.audios = arrayFiles;
			this.$emit('input', arrayFiles);
		}
	}
}
</script>

<style lang="scss">
.audio-drop-zone {
	.audio-container {
		border: 2px dashed #ccc;
		border-radius: 11px;
		padding: 8px 12px;
		cursor: pointer;
	
		&__item {
			padding: 3px 6px;
		}
	}
}
</style>