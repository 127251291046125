import { useRestore } from '@/configs/generated/globals';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		customFilters: []
	},

	actions: {
		fetchAll({ commit }, { type }) {
			return axios.get('/custom-filters', { params: { type } }).then(({ data }) => {
				const storeData = data.filter(c => !useRestore.customFilters ? !c.deletedAt : true);
				commit(types.STORE_CUSTOM_FILTERS, storeData);
				return storeData;
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/custom-filters/${slug}`, configs).then(({ data }) => data);
		},
		create({ commit }, { type, typeName, name, description, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { type, typeName, name, description, image, imageName }
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			return axios.post('/custom-filters', formData, configs).then(({ data }) => data);
		},
		update({ commit }, { id, type, typeName, name, description, image, imagePath, imageName, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			
			const formData = new FormData();
			const requestData = { type, typeName, name, description, image, imageName }
			Object.keys(requestData).forEach(key => {
				if (requestData[key]) {
					formData.append(key, requestData[key]);
				}
			})

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));
			formData.append('_method', 'PUT');

			return axios.post(`/custom-filters/${id}`, formData, configs).then(({ data }) => data);
		},
		delete({ commit }, { id }) {
			return axios.post(`/custom-filters/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.post(`/custom-filters/${id}`, { _method: 'PATCH' })
		},
	},

	mutations: {
		[types.STORE_CUSTOM_FILTERS](state, customFilters) {
			state.customFilters = customFilters;
		}
	},

	getters: {
		fetchCustomFilterById: state => id => state.customFilters.find(c => c.id === id),
		fetchCustomFilterBySlug: state => slug => state.customFilters.find(c => c.slug === slug),
	}
}