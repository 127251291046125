import * as configs from '@/configs/routes.config'
import * as routesTitles from '@/configs/translates.config'
import { customServices } from '@/configs/custom-pages.config'
import { customFilters } from '@/configs/custom-filters.config'

import Login from '@/pages/Login.vue'
import Preview from '@/pages/Preview.vue'
import Dashboard from '@/pages/Dashboard.vue'
import Categories from '@/pages/categories/Categories.vue'
import Sets from '@/pages/grouping-pages/Sets.vue'
import Category from '@/pages/categories/Category.vue'
import Filters from '@/pages/grouping-pages/Filters.vue'
import Keywords from '@/pages/grouping-pages/Keywords.vue'
import Publishers from '@/pages/grouping-pages/Publishers.vue'
import Products from '@/pages/products/Products.vue'
import Product from '@/pages/products/Product.vue'
import News from '@/pages/news/News.vue'
import NewsIndex from '@/pages/news/NewsIndex.vue'
import Services from '@/pages/services/Services.vue'
import Service from '@/pages/services/Service.vue'
import CustomServices from '@/pages/custom-services/CustomServices.vue'
import CustomService from '@/pages/custom-services/CustomService.vue'
import CustomFilters from '@/pages/custom-filters/CustomFilters.vue'
import Albums from '@/pages/albums/Albums.vue'
import Videos from '@/pages/videos/Videos.vue'
import Orders from '@/pages/orders/Orders.vue'
import Order from '@/pages/orders/Order.vue'
import LoyaltyPoints from '@/pages/orders/LoyaltyPoints.vue'
import Plans from '@/pages/plans/Plans.vue'
import Plan from '@/pages/plans/Plan.vue'
import Coupons from '@/pages/offers/Coupons.vue'
import Offers from '@/pages/offers/Offers.vue'
import Users from '@/pages/users/Users.vue'
import UserInfo from '@/pages/users/UserInfo.vue'
import Ratings from '@/pages/ratings/Ratings.vue'
import AffiliateMarketer from '@/pages/affiliate-marketers/AffiliateMarketer.vue'
import AffiliateMarketers from '@/pages/affiliate-marketers/AffiliateMarketers.vue'
import Sellers from '@/pages/sellers/Sellers.vue'
import SystemUsersRoles from '@/pages/users/SystemUsersRoles.vue'
import SystemUsers from '@/pages/users/SystemUsers.vue'
import Branches from '@/pages/branches/Branches.vue'
import Branch from '@/pages/branches/Branch.vue'
import Slider from '@/pages/Sliders.vue'
import Subscriptions from '@/pages/users/Subscriptions.vue'
import CompanyValues from '@/pages/company-values/CompanyValues.vue'
import CompanyValue from '@/pages/company-values/CompanyValue.vue'
import CompanyHistories from '@/pages/company-histories/CompanyHistories.vue'
import CompanyHistory from '@/pages/company-histories/CompanyHistory.vue'
import AboutUs from '@/pages/AboutUs.vue'
import FileManager from '@/pages/FileManager.vue'
import SEOpage from '@/pages/seo/SEOpage.vue'

const routes = [
	/* dashboard */
	{
		path: `/${configs.dashboard.inPathName}`,
		name: configs.dashboard.name,
		component: Dashboard,
		title: routesTitles.dashboard,
		isAddEditPage: configs.dashboard.isAddEditPage
	},
	/* file manager */
	{
		path: `/${configs.fileManager.inPathName}`,
		name: configs.fileManager.name,
		component: FileManager,
		title: routesTitles.fileManager,
	},
	/* sets */
	{
		path: `/${configs.sets.inPathName}`,
		name: configs.sets.name,
		component: Sets,
		title: routesTitles.sets,
		isAddEditPage: configs.sets.isAddEditPage
	},
	/* categories */
	{
		path: `/${configs.categories.inPathName}`,
		name: configs.categories.name,
		component: Categories,
		title: routesTitles.categories,
		isAddEditPage: configs.categories.isAddEditPage
	},
	/* category */
	{
		path: `/${configs.category.inPathName}/:id?`,
		name: configs.category.name,
		component: Category,
		title: routesTitles.category,
		isAddEditPage: configs.category.isAddEditPage
	},
	/* filters */
	{
		path: `/${configs.filters.inPathName}`,
		name: configs.filters.name,
		component: Filters,
		title: routesTitles.filters,
		isAddEditPage: configs.filters.isAddEditPage
	},
	/* keywords */
	{
		path: `/${configs.keywords.inPathName}`,
		name: configs.keywords.name,
		component: Keywords,
		title: routesTitles.keywords,
		isAddEditPage: configs.keywords.isAddEditPage
	},
	/* publishers */
	{
		path: `/${configs.publishers.inPathName}`,
		name: configs.publishers.name,
		component: Publishers,
		title: routesTitles.publishers,
		isAddEditPage: configs.publishers.isAddEditPage
	},
	/* sellers */
	{
		path: `/${configs.sellers.inPathName}`,
		name: configs.sellers.name,
		component: Sellers,
		title: routesTitles.sellers,
		isAddEditPage: configs.sellers.isAddEditPage
	},
	/* products */
	{
		path: `/${configs.products.inPathName}`,
		name: configs.products.name,
		component: Products,
		title: routesTitles.products,
		isAddEditPage: configs.products.isAddEditPage
	},
	/* product */
	{
		path: `/${configs.product.inPathName}/:slug?/:id?`,
		name: configs.product.name,
		component: Product,
		title: routesTitles.product,
		isAddEditPage: configs.product.isAddEditPage
	},
	/* news */
	{
		path: `/${configs.news.inPathName}`,
		name: configs.news.name,
		component: News,
		title: routesTitles.news,
		isAddEditPage: configs.news.isAddEditPage
	},
	/* newsIndex */
	{
		path: `/${configs.newsIndex.inPathName}/:slug?/:id?`,
		name: configs.newsIndex.name,
		component: NewsIndex,
		title: routesTitles.newsIndex,
		isAddEditPage: configs.newsIndex.isAddEditPage
	},
	/* services */
	{
		path: `/${configs.services.inPathName}`,
		name: configs.services.name,
		component: Services,
		title: routesTitles.services,
		isAddEditPage: configs.services.isAddEditPage
	},
	/* service */
	{
		path: `/${configs.service.inPathName}/:slug?/:id?`,
		name: configs.service.name,
		component: Service,
		title: routesTitles.service,
		isAddEditPage: configs.service.isAddEditPage
	},
	/* orders */
	{
		path: `/${configs.orders.inPathName}`,
		name: configs.orders.name,
		component: Orders,
		title: routesTitles.orders,
		isAddEditPage: configs.orders.isAddEditPage
	},
	/* order */
	{
		path: `/${configs.order.inPathName}/:id`,
		name: configs.order.name,
		component: Order,
		title: routesTitles.order,
		isAddEditPage: configs.order.isAddEditPage
	},
	// loyalty points
	{
		path: `/${configs.loyaltyPoints.inPathName}`,
		name: configs.loyaltyPoints.name,
		component: LoyaltyPoints,
		title: routesTitles.loyaltyPoints,
		isAddEditPage: configs.loyaltyPoints.isAddEditPage
	},
	/* plans */
	{
		path: `/${configs.plans.inPathName}`,
		name: configs.plans.name,
		component: Plans,
		title: routesTitles.plans,
		isAddEditPage: configs.plans.isAddEditPage
	},
	/* plan */
	{
		path: `/${configs.plan.inPathName}/:slug?/:id?`,
		name: configs.plan.name,
		component: Plan,
		title: routesTitles.plan,
		isAddEditPage: configs.plan.isAddEditPage
	},
	/* coupons */
	{
		path: `/${configs.coupons.inPathName}/:slug?/:id?`,
		name: configs.coupons.name,
		component: Coupons,
		title: routesTitles.coupons,
		isAddEditPage: configs.coupons.isAddEditPage
	},
	/* offers */
	{
		path: `/${configs.offers.inPathName}/:slug?/:id?`,
		name: configs.offers.name,
		component: Offers,
		title: routesTitles.offers,
		isAddEditPage: configs.offers.isAddEditPage
	},
	/* company-values */
	{
		path: `/${configs.companyValues.inPathName}`,
		name: configs.companyValues.name,
		component: CompanyValues,
		title: routesTitles.companyValues,
		isAddEditPage: configs.companyValues.isAddEditPage
	},
	/* company-value */
	{
		path: `/${configs.companyValue.inPathName}/:slug?/:id?`,
		name: configs.companyValue.name,
		component: CompanyValue,
		title: routesTitles.companyValue,
		isAddEditPage: configs.companyValue.isAddEditPage
	},
	/* company-histories */
	{
		path: `/${configs.companyHistories.inPathName}`,
		name: configs.companyHistories.name,
		component: CompanyHistories,
		title: routesTitles.companyHistories,
		isAddEditPage: configs.companyHistories.isAddEditPage
	},
	/* company-history */
	{
		path: `/${configs.companyHistory.inPathName}/:slug?/:id?`,
		name: configs.companyHistory.name,
		component: CompanyHistory,
		title: routesTitles.companyHistory,
		isAddEditPage: configs.companyHistory.isAddEditPage
	},
	/* albums */
	{
		path: `/${configs.albums.inPathName}`,
		name: configs.albums.name,
		component: Albums,
		title: routesTitles.albums,
		isAddEditPage: configs.albums.isAddEditPage
	},
	/* videos */
	{
		path: `/${configs.videos.inPathName}`,
		name: configs.videos.name,
		component: Videos,
		title: routesTitles.videos,
		isAddEditPage: configs.videos.isAddEditPage
	},
	/* sliders */
	{
		path: `/${configs.sliders.inPathName}`,
		name: configs.sliders.name,
		component: Slider,
		title: routesTitles.sliders,
		isAddEditPage: configs.sliders.isAddEditPage
	},
	/* users */
	{
		path: `/${configs.users.inPathName}`,
		name: configs.users.name,
		component: Users,
		title: routesTitles.users,
		isAddEditPage: configs.users.isAddEditPage
	},
	/* user info */
	{
		path: `/${configs.userInfo.inPathName}/:id`,
		name: configs.userInfo.name,
		component: UserInfo,
		title: routesTitles.userInfo,
		isAddEditPage: configs.userInfo.isAddEditPage
	},
	/* users */
	{
		path: `/${configs.ratings.inPathName}`,
		name: configs.ratings.name,
		component: Ratings,
		title: routesTitles.ratings,
		isAddEditPage: configs.ratings.isAddEditPage
	},
	/* affiliate marketers */
	{
		path: `/${configs.affiliateMarketers.inPathName}`,
		name: configs.affiliateMarketers.name,
		component: AffiliateMarketers,
		title: routesTitles.affiliateMarketers,
		isAddEditPage: configs.affiliateMarketers.isAddEditPage
	},
	/* affiliate marketer */
	{
		path: `/${configs.affiliateMarketer.inPathName}/:id`,
		name: configs.affiliateMarketer.name,
		component: AffiliateMarketer,
		title: routesTitles.affiliateMarketer,
		isAddEditPage: configs.affiliateMarketer.isAddEditPage
	},
	/* system users */
	{
		path: `/${configs.systemUsers.inPathName}`,
		name: configs.systemUsers.name,
		component: SystemUsers,
		title: routesTitles.systemUsers,
		isAddEditPage: configs.systemUsers.isAddEditPage
	},
	/* system users roles */
	{
		path: `/${configs.systemUsersRoles.inPathName}`,
		name: configs.systemUsersRoles.name,
		component: SystemUsersRoles,
		title: routesTitles.systemUsersRoles,
		isAddEditPage: configs.systemUsersRoles.isAddEditPage
	},
	/* branches */
	{
		path: `/${configs.branches.inPathName}`,
		name: configs.branches.name,
		component: Branches,
		title: routesTitles.branches,
		isAddEditPage: configs.branches.isAddEditPage
	},
	/* service */
	{
		path: `/${configs.branch.inPathName}/:slug?/:id?`,
		name: configs.branch.name,
		component: Branch,
		title: routesTitles.branch,
		isAddEditPage: configs.branch.isAddEditPage
	},
	/* subscriptions */
	{
		path: `/${configs.subscriptions.inPathName}`,
		name: configs.subscriptions.name,
		component: Subscriptions,
		title: routesTitles.subscriptions,
		isAddEditPage: configs.subscriptions.isAddEditPage
	},
	/* about us */
	{
		path: `/${configs.aboutUs.inPathName}`,
		name: configs.aboutUs.name,
		component: AboutUs,
		title: routesTitles.aboutUs,
		isAddEditPage: configs.aboutUs.isAddEditPage
	},
	/* seo */
	{
		path: `/${configs.SEO.inPathName}`,
		name: configs.SEO.name,
		component: SEOpage,
		title: routesTitles.SEO,
		isAddEditPage: configs.SEO.isAddEditPage
	},
	/* preview */
	{
		path: `/${configs.preview.inPathName}`,
		name: configs.preview.name,
		component: Preview,
		title: routesTitles.preview,
	}
];

const availableRoute = [
	/* login */
	{
		path: `/${configs.login.inPathName}`,
		name: configs.login.name,
		component: Login,
		title: routesTitles.login,
		isAddEditPage: configs.login.isAddEditPage
	},
	
	{
		path: '*',
		redirect: '/'
	}
];

/* custom pages */
// custom services
if (customServices.length) {
	customServices.forEach(page => {
		availableRoute.push({
			path: `/${page.mainPageRoute.inPathName}/:type`,
			name: page.mainPageRoute.name,
			component: CustomServices,
			title: page.translates,
		})
		availableRoute.push({
			path: `/${page.addEditPageRoute.inPathName}/:type/:slug?/:id?`,
			name: page.addEditPageRoute.name,
			component: CustomService,
			title: page.translates,
			isAddEditPage: true
		})
	})
}
/* custom filters */
if (customFilters.length) {
	customFilters.forEach(page => {
		availableRoute.push({
			path: `/${page.route.inPathName}/:type`,
			name: page.route.name,
			component: CustomFilters,
			title: page.translates,
		})
	})
}

Object.keys(configs).filter(key => configs[key].show).forEach(key => {
	availableRoute.push(routes.find(route => route.name === configs[key].name));
})

export default availableRoute