<template>
	<div>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="sellers"
			:ids="deleteItemId"
			@on-delete="fetchSellers"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete')}}</span> 
			{{$t('Sellers.the-seller')}}
		</bee-delete-dialog>

		<!-- restore dialog -->
		<bee-restore-dialog
			v-model="restoreDialog"
			moduleType="sellers"
			:ids="restoreItemId"
			@on-restore="fetchSellers"
		>
			{{$t('are-you-sure-you-want-to')}} 
			<span class="text-uppercase orange--text text-darken-1">{{$t('restore')}}</span> 
			{{$t('Sellers.the-seller')}}
		</bee-restore-dialog>

		<!-- confirm dialog -->
		<v-dialog
			v-model="changeStateDialog"
			width="400"
			:persistent="changeStateLoading"
		>
			<v-card class="rounded-medium">
				<v-card-title class="justify-center text-uppercase text-h6">
					{{ $t('confirm') + ' ' + confirmText.title }}
				</v-card-title>
				<v-card-text class="text-body-1 pb-2">
					{{$t('Sellers.are-you-sure-you-want-to-$-this-seller', { state: confirmText.title })}}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="primary"
						class="rounded-small"
						:loading="changeStateLoading"
						:disabled="changeStateLoading"
						@click="changeStateState()"
					>
						{{confirmText.title}}
					</v-btn>
					<v-btn
						class="rounded-small"
						:disabled="changeStateLoading"
						@click="changeStateDialog = false;"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- add/edit dialog -->
		<v-dialog
			v-model="dialog"
			width="700"
			:persistent="dialogLoading"
		>
			<v-form ref="form" @submit.prevent="submit" :disabled="dialogLoading">
				<v-card class="rounded-medium">
					<v-card-title
						class="justify-center text-uppercase text-h6"
						:class="{ 'mb-3': !multiLangInputs, 'pb-2': multiLangInputs }"
					>
						{{ editItemId ? $t('edit') : $t('add')}} {{$t('Sellers.seller')}}
					</v-card-title>
					<v-card-text class="pb-2">
						<!-- lang tabs -->
						<v-lang-tabs
							v-if="multiLangInputs"
							v-model="lang"
							:checked-langs="acceptedToSaveLangs"
							:disable-non-selected="dialogLoading"
							background-color="bgColor"
							class="mb-4"
							@change="updateFieldsFromDraft"
						/>

						<!-- inputs -->
						<v-row dense class="mt-0">
							<!-- name -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="name"
									:label="$t('inputs.name')"
									outlined
									dense
									class="rounded-small"
									hide-details
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<!-- type -->
							<v-col md="4" sm="6" cols="12">
								<v-autocomplete
									v-model.number="type"
									:label="$t('inputs.type')"
									:items="sellerTypes"
									item-text="name"
									item-value="id"
									outlined
									dense
									class="rounded-small"
									hide-details
									:rules="rules.requiredAsNumber"
								></v-autocomplete>
							</v-col>
							
							<!-- userId -->
							<v-col md="4" sm="6" cols="12">
								<v-autocomplete
									v-model.number="userId"
									:label="$t('inputs.system-user-account')"
									:items="systemUsers.filter(c => c.userName !== 'admin')"
									item-text="fullName"
									item-value="id"
									outlined
									dense
									hide-details
									class="rounded-small"
									:rules="rules.requiredAsNumber"
								></v-autocomplete>
							</v-col>

							<!-- contract date -->
							<v-col md="4" sm="6" cols="12">
								<v-menu
									v-model="contractDateMenu"
									transition="scale-transition"
									offset-y
									:close-on-content-click="false"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="contractDate"
											:label="$t('inputs.contract-date')"
											prepend-inner-icon="mdi-calendar"
											outlined
											dense
											hide-details
											class="rounded-medium"
											readonly
											:rules="rules.required"
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="contractDate"
										@input="contractDateMenu = false"
									></v-date-picker>
								</v-menu>
							</v-col>

							<!-- contract end date -->
							<v-col md="4" sm="6" cols="12">
								<v-menu
									v-model="contractEndDateMenu"
									transition="scale-transition"
									offset-y
									:close-on-content-click="false"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="contractEndDate"
											:label="$t('inputs.contract-end-date')"
											prepend-inner-icon="mdi-calendar"
											outlined
											dense
											hide-details
											class="rounded-medium"
											readonly
											v-bind="attrs"
											v-on="on"
											:rules="contractDate ? [val => moment(contractDate).isBefore(contractEndDate)] : []"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="contractEndDate"
										@input="contractEndDateMenu = false"
									></v-date-picker>
								</v-menu>
							</v-col>
						
							<!-- email -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="email"
									:label="$t('inputs.email')"
									outlined
									dense
									hide-details
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- phone -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="phone"
									:label="$t('inputs.phone')"
									outlined
									dense
									hide-details
									:rules="rules.required"
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- landline -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="landline"
									:label="$t('inputs.landline')"
									outlined
									dense
									hide-details
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- country -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="country"
									:label="$t('inputs.country')"
									outlined
									dense
									hide-details
									:rules="rules.required"
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- city -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="city"
									:label="$t('inputs.city')"
									outlined
									dense
									hide-details
									:rules="rules.required"
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- address -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="address"
									:label="$t('inputs.address')"
									outlined
									dense
									hide-details
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- residency -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="residency"
									:label="$t('inputs.residency')"
									outlined
									dense
									hide-details
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- company no -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="companyNo"
									:label="$t('inputs.company-no')"
									outlined
									dense
									hide-details
									:rules="rules.required"
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- commercial no -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="commercialNo"
									:label="$t('inputs.commercial-no')"
									outlined
									dense
									hide-details
									:rules="rules.required"
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- tax no -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="taxNo"
									:label="$t('inputs.tax-no')"
									outlined
									dense
									hide-details
									:rules="rules.required"
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- postNo -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="postNo"
									:label="$t('inputs.post-no')"
									outlined
									dense
									hide-details
									class="rounded-small"
									:rules="rules.required"
								></v-text-field>
							</v-col>

							<!-- deliveryCompanyNo -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="deliveryCompanyNo"
									:label="$t('inputs.delivery-company-no')"
									outlined
									dense
									hide-details
									:rules="rules.required"
									class="rounded-small"
								></v-text-field>
							</v-col>

							<!-- freeDeliveryLimit -->
							<v-col md="4" sm="6" cols="12">
								<v-text-field
									v-model="freeDeliveryLimit"
									:label="$t('inputs.free-delivery-limit')"
									outlined
									dense
									hide-details
									class="rounded-small"
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							class="rounded-small"
							:loading="dialogLoading"
							:disabled="dialogLoading"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							@click="dialog = false"
							class="rounded-small"
							:disabled="dialogLoading"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- filter -->
		<v-card class="rounded-medium mb-3">
			<v-card-text class="pa-2">
				<v-row class="align-center">
					<!-- add -->
					<v-col md="8" cols="12" class="text-sm-start text-center">
						<v-btn
							dark
							color="darkGreen"
							class="rounded-small"
							@click="dialog = true"
						>
							<v-icon>mdi-plus</v-icon>
							{{$t('Sellers.add-new-seller')}}
						</v-btn>
					</v-col>
					<!-- search -->
					<v-col md="4" cols="12" class="d-flex flex-sm-row flex-column align-center text-md-start text-center">
						<v-text-field
							v-model="search"
							:label="$t('search')"
							hide-details
							outlined
							dense
							prepend-inner-icon="mdi-magnify"
							class="rounded-medium align-self-stretch me-sm-6 mb-sm-0 mb-5"
						></v-text-field>
						<v-btn
							:color="isFiltered ? null : 'primary'"
							class="rounded-small"
							@click="isFiltered = !isFiltered"
						>
							{{isFiltered ? $t('cancel') : $t('search')}}
						</v-btn>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
		<!-- table -->
		<v-data-table
			:headers="customizedHeaders"
			:items="loading ? [] : filteredSellers"
			:loading="loading"
			dense
			:items-per-page="15"
			class="elevation-3 rounded-medium"
		>
			<!-- actions header -->
			<template v-slot:header.actions="{ header }">
				<div class="d-flex align-center" :class="`justify-${header.align}`">
					<span class="px-0" :class="header.class">{{header.text}}</span>
					<v-btn
						small
						icon
						@click="$eventBus.$emit(
							'headers-control',
							allHeaders.filter(c => !c.hide),
							customizedHeaders,
							(newHeaders) => {
								$eStorage.l.sellersHeaders = newHeaders;
							}
						)"
					>
						<v-icon size="18">mdi-cog</v-icon>
					</v-btn>
				</div>
			</template>

			<!-- type -->
			<template v-slot:item.type="{ item }">
				{{
					['number', 'string'].includes(typeof item.type)
						? sellerTypes.find(c => c.id === +item.type)?.name
						: item.type
				}}
			</template>
			
			<!-- userId -->
			<template v-slot:item.userId="{ item }">
				{{getSystemUserById(item.userId)?.fullName ?? '-'}}
			</template>

			<!-- contractDate -->
			<template v-slot:item.contractDate="{ item }">
				{{item.contractDate ? moment(item.contractDate).format('YYYY-MM-DD') : '-'}}
			</template>

			<!-- contractEndDate -->
			<template v-slot:item.contractEndDate="{ item }">
				{{item.contractEndDate ? moment(item.contractEndDate).format('YYYY-MM-DD') : '-'}}
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<template v-if="!item.deletedAt">
					<tooltip :text="$t('tooltip.banned-state')">
						<v-btn
							:color="item.isBanned ? 'red darken-1' : 'success'"
							text
							dark
							small
							class="me-1 px-1"
							min-width="20px"
							@click="confirmChangeState(item, 1)"
						>
							<v-icon>{{item.isBanned ? 'mdi-account-cancel' : 'mdi-account-badge'}}</v-icon>
						</v-btn>
					</tooltip>
					<tooltip :text="$t('tooltip.validation-state')">
						<v-btn
							:color="item.isVerified ? 'success' : 'deep-orange lighten-1'"
							text
							dark
							small
							class="me-1 px-1"
							min-width="20px"
							@click="confirmChangeState(item, 2)"
						>
							<v-icon>{{item.isVerified ? 'mdi-account-check' : 'mdi-account-alert'}}</v-icon>
						</v-btn>
					</tooltip>
					<tooltip :text="$t('tooltip.edit')">
						<v-btn
							color="blue darken-4"
							dark
							small
							class="me-1 px-1 flex-shrink-1"
							min-width="20px"
							@click="editItemId = item.id; dialog = true;"
						><v-icon>mdi-pencil</v-icon></v-btn>
					</tooltip>
					<tooltip :text="$t('tooltip.delete')">
						<v-btn
							color="red darken-1"
							dark
							small
							class="px-1"
							min-width="20px"
							@click="deleteItemId = item.id; deleteDialog = true"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</template>
				<tooltip v-else :text="$t('tooltip.restore')">
					<v-btn
						color="orange darken-1"
						dark
						small
						class="px-1"
						min-width="20px"
						@click="restoreItemId = item.id; restoreDialog = true"
					>
						<v-icon>mdi-restore</v-icon>
					</v-btn>
				</tooltip>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { defaultLang, langs, multiLangInputs } from '@/configs/translates.config';
import langsDraft from '@/mixins/langs-draft';
import { sellerTypes } from '@/helpers/enums';
import moment from 'moment';

export default {
	name: 'Seller',
	mixins: [langsDraft],

	data: () => ({
		dialog: false,
		deleteDialog: false,

		editItemId: null,
		deleteItemId: null,

		restoreDialog: false,
		restoreItemId: null,

		contractDateMenu: false,
		contractEndDateMenu: false,

		changeStateDialog: false,
		changeStateLoading: false,
		changeStateType: null,
		confirmText: {
			title: null,
			message: null,
		},

		// filter
		search: null,
		isFiltered: false,

		// configs
		multiLangInputs,

		// helpers
		loading: false,
		dialogLoading: false,
		lang: defaultLang,
		sellerTypes,
		rules,
		moment,

		// draft for languages 
		draft: {},
		draftNames: ['name'],

		// data
		name: null,
		type: null,
		userId: null,
		email: null,
		contractDate: null,
		contractEndDate: null,
		phone: null,
		landline: null,
		country: null,
		city: null,
		address: null,
		residency: null,
		companyNo: null,
		commercialNo: null,
		taxNo: null,
		postNo: null,
		deliveryCompanyNo: null,
		freeDeliveryLimit: null,
	}),

	watch: {
		dialog(val) {
			if (val) {
				if (this.editItemId) {
					if (multiLangInputs) {
						this.fetchSeller();
					} else {
						const item = this.getSellerById(this.editItemId);
						this.name = item.fullName;
						this.type = item.type;
						this.userId = item.userId;
						this.email = item.email;
						this.contractDate = moment(item.contractDate).format('YYYY-MM-DD');
						this.contractEndDate = moment(item.contractEndDate).format('YYYY-MM-DD');
						this.phone = item.phone;
						this.landline = item.landLine;
						this.country = item.country;
						this.city = item.city;
						this.address = item.address;
						this.residency = item.residency;
						this.companyNo = item.companyNo;
						this.commercialNo = item.commercialNo;
						this.taxNo = item.taxNo;
						this.postNo = item.postNo;
						this.deliveryCompanyNo = item.deliveryCompanyNo;
						this.freeDeliveryLimit = item.freeDeliveryLimit;
					}
				}
			} else {
				this.$refs.form.reset();
				this.resetDraft();
				this.editItemId = null;
				this.lang = defaultLang;
			}
		},

		changeStateDialog(val) {
			if (!val) {
				this.editItemId = null;
				this.changeStateType = null;
				this.confirmText.title = null;
				this.confirmText.message = null;
			}
		}
	},

	computed: {
		...mapState({
			sellers: state => state.sellers.sellers,
			systemUsers: state => state.systemUsers.systemUsers.filter(c => !c.deletedAt),
		}),
		...mapGetters({
			getSellerById: 'sellers/getSellerById',
			getSystemUserById: 'systemUsers/getSystemUserById',
		}),

		filteredSellers() {
			return this.filterByName(this.isFiltered, this.sellers, this.search);
		},

		allHeaders() {
			const headers = [
				{
					text: this.$t('headers.name'),
					value: 'fullName',
					align: 'center',
					class: 'primary--text',
					cellClass: 'primary--text',
					noRemove: true,
				},
				{
					text: this.$t('headers.type'),
					value: 'type',
					align: 'center',
				},
				{
					text: this.$t('headers.system-account'),
					value: 'userId',
					align: 'center',
				},
				{
					text: this.$t('headers.contract-date'),
					value: 'contractDate',
					align: 'center',
				},
				{
					text: this.$t('headers.contract-end-date'),
					value: 'contractEndDate',
					align: 'center',
				},
				{
					text: this.$t('headers.email'),
					value: 'email',
					align: 'center',
				},
				{
					text: this.$t('headers.phone'),
					value: 'phone',
					align: 'center',
				},
				{
					text: this.$t('headers.landline'),
					value: 'landLine',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.country'),
					value: 'country',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.city'),
					value: 'city',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.address'),
					value: 'address',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.residency'),
					value: 'residency',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.company-no'),
					value: 'companyNo',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.commercial-no'),
					value: 'commercialNo',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.tax-no'),
					value: 'taxNo',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.post-no'),
					value: 'postNo',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.delivery-company-no'),
					value: 'deliveryCompanyNo',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.free-delivery-limit'),
					value: 'freeDeliveryLimit',
					align: 'center',
					notInDefaultView: true,
				},
				{
					text: this.$t('headers.actions'),
					value: 'actions',
					align: 'center',
					width: 200,
					sortable: false,
					noRemove: true,
					fixed: true
				},
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2'
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2'
			}

			return headers
		},

		customizedHeaders() {
			let headers = [];
			if (this.$eStorage.l.sellersHeaders.length) {
				headers = this.$eStorage.l.sellersHeaders;
			} else {
				headers = this.allHeaders.filter(c => !c.notInDefaultView);
			}
			return headers.filter(c => !c.hide);
		},

		acceptedToSaveLangs() {
			const acceptedLangs = langs.reduce((res, a) => { res[a.key] = false; return res }, {})
			langs.forEach(lang => {
				if (this.draft.name[lang.key]) {
					acceptedLangs[lang.key] = true;
				}
			});
			return acceptedLangs;
		}
	},

	methods: {
		async submit() {
			if (this.$refs.form.validate()) {
				this.dialogLoading = true;
				const promises = [];
				let itemData = null;

				const submitRequest = (
					{ name, lang },
					id = this.editItemId
				) => {
					const actionName = id ? 'update' : 'create';
					return this.$store.dispatch(`sellers/${actionName}`, {
						id: id,
						fullName: name,
						type: this.type,
						userId: this.userId,
						email: this.email,
						contractDate: this.contractDate,
						contractEndDate: this.contractEndDate,
						phone: this.phone,
						landLine: this.landline,
						country: this.country,
						city: this.city,
						address: this.address,
						residency: this.residency,
						companyNo: this.companyNo,
						commercialNo: this.commercialNo,
						taxNo: this.taxNo,
						postNo: this.postNo,
						deliveryCompanyNo: this.deliveryCompanyNo,
						freeDeliveryLimit: this.freeDeliveryLimit,
						acceptLanguage: lang
					});
				};

				const filteredLangs = langs.filter(c => multiLangInputs || c.key === defaultLang);
				for (let index = 0; index < filteredLangs.length; index++) {
					const lang = filteredLangs[index].key;

					const name = this.draft.name[lang];

					const dataDueLang = { name, lang };

					if (name) {
						if (!itemData) {
							await submitRequest(dataDueLang).then(data => { itemData = data });
						} else {
							promises.push(submitRequest(dataDueLang, itemData.id));
						}
					}
				}
				
				Promise.all(promises).then((data) => {
					const message =
						this.editItemId
							? this.$t('$-is-updated-successfully', { name: this.$t('Sellers.seller') })
							: this.$t('$-is-created-successfully', { name: this.$t('Sellers.seller') });
					this.$eventBus.$emit('show-snackbar', false, message);

					this.fetchSellers();
					this.dialog = false;
				}).catch(() => {
					this.editItemId = itemData.id;
				}).finally(() => {
					this.dialogLoading = false;
				})
			}
		},

		confirmChangeState(item, type) {
			this.editItemId = item.id;
			this.changeStateType = type;
			let state;
			switch (type) {
			case 1: {
				state = !item.isBanned ? this.$t('Sellers.banned') : this.$t('Sellers.unbanned');
				break;
			}
			case 2: {
				state = !item.isVerified ? this.$t('Sellers.verified') : this.$t('Sellers.unverified');
				break;
			}
			}
			this.confirmText.title = state;
			this.confirmText.message = this.$t('Sellers.are-you-sure-you-want-to-$-this-user', { state })
			
			this.changeStateDialog = true;
		},

		changeStateState() {
			this.changeStateLoading = true;
			let actionName;
			if (this.changeStateType === 1) actionName = 'changeBanned';
			else if (this.changeStateType === 2) actionName = 'changeVerify';

			this.$store.dispatch(`sellers/${actionName}`, {
				id: this.editItemId
			}).then(() => {
				this.fetchSellers();
				this.changeStateDialog = false;
			}).finally(() => {
				this.changeStateLoading = false;
			})
		},

		fetchSellers(isFirstLoad = false) {
			this.loading = true;
			return this.$store.dispatch('sellers/fetchAll')
				.finally(() => {
					if (!isFirstLoad) this.loading = false;
				})
		},

		fetchSeller() {
			this.dialogLoading = true;
			const slug = this.getSellerById(this.editItemId).slug;

			const promises = [];
			langs.filter(c => multiLangInputs || c.key === defaultLang).forEach((c, index) => {
				promises.push(
					this.$store.dispatch('sellers/fetchBySlug', { slug, acceptLanguage: c.key })
						.then((data) => {
							this.draft.name[c.key] = data.fullName;
							if (index === 0) {
								this.type = data.type;
								this.userId = data.userId;
								this.email = data.email;
								this.contractDate =
									data.contractDate
										? moment(data.contractDate).format('YYYY-MM-DD')
										: null;
								this.contractEndDate =
									data.contractEndDate
										? moment(data.contractEndDate).format('YYYY-MM-DD')
										: null;
								this.phone = data.phone;
								this.landline = data.landLine;
								this.country = data.country;
								this.city = data.city;
								this.address = data.address;
								this.residency = data.residency;
								this.companyNo = data.companyNo;
								this.commercialNo = data.commercialNo;
								this.taxNo = data.taxNo;
								this.postNo = data.postNo;
								this.deliveryCompanyNo = data.deliveryCompanyNo;
								this.freeDeliveryLimit = data.freeDeliveryLimit;
							}
						})
				);
			});

			Promise.all(promises).then(() => {
				this.name = this.draft.name[this.lang];
			}).finally(() => {
				this.dialogLoading = false;
			})
		}
	},

	created() {
		this.loading = true;
		Promise.all([
			this.fetchSellers(true),
			!this.systemUsers.length ? this.$store.dispatch('systemUsers/fetchAll') : null,
		]).finally(() => {
			this.loading = false;
		});
	}
}
</script>

<style>

</style>