import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import midad, { $eventBus } from './plugins/midad'
import i18n from './i18n/i18n'
import axios from 'axios'
import moment from 'moment/moment'
import { BASE_URL } from './helpers/constants'
import { login } from './configs/routes.config'
import { defaultLang, multiLangUI } from './configs/translates.config'
import { eStorage as es } from '@beetronix/estorage'
import { eStorage } from './plugins/eStorage'

Vue.use(VueMeta)

Vue.config.productionTip = false;

// TODO move axios declarations to sprite file 'axios.js' 
window.axios = axios.create({
	baseURL: BASE_URL + '/api',
	headers: { common: { 'accept-language': multiLangUI ? i18n.locale : defaultLang } }
})
let controller = {};
if ('AbortController' in window) {
	controller = new AbortController();
	router.beforeEach((to, from, next) => {
		if (from.name) { controller.abort(); controller = new AbortController(); }
		next();
	});
}
window.axios.interceptors.request.use((configs) => {
	configs.signal = controller.signal;
	return configs;
});
window.axios.interceptors.response.use(
	function (response) {
		/*
			TODO
			return response.data instead of response
			to remove (.then(({ data }) => data)) from store modules
		*/
		return response;
	},
	
	function (error) {
		if (error.code === 'ERR_CANCELED') return Promise.reject(error);
		if (!error.response || !window.navigator.onLine) {
			$eventBus.$emit('show-snackbar', true, i18n.t('messages.check-your-connection'))
		}
		if (error.response && error.response.status === 401) {
			store.dispatch('auth/logout');
			router.replace({ name: login.name })
			$eventBus.$emit('show-snackbar', false, i18n.t('messages.session-is-end'))
		}
		if (router.currentRoute.name !== login.name) {
			$eventBus.$emit('show-snackbar', true, error.response.data.message || error.response.data.error)
		}
		return Promise.reject(error);
	}
);

if (es.l.has('auth')) {
	const remainingMinutes = moment.duration(moment(es.l.get('expiry-date'))).minutes();
	if (remainingMinutes < 0) {
		store.dispatch('auth/logout');
	} else {
		store.state.auth.authenticated = true;
		const auth = es.l.get('auth')
		window.axios.defaults.headers.common.authorization = `Bearer ${auth.token}`;
		store.state.auth.self.id = auth.id;
		store.state.auth.self.name = auth.name;
		store.state.auth.self.username = auth.username;
		store.state.auth.self.expiresIn = auth.expiresIn;
		store.state.auth.self.hasPermission = auth.hasPermission;
	}
}
new Vue({
	router,
	store,
	vuetify,
	eStorage,
	midad,
	i18n,
	render: h => h(App)
}).$mount('#app')

// **elasticemail**
// API KEY: BAC37FCDBA4217467B45C748CD4AC53E8FAAF484AE908B20FAF683B41C074DA878DE9603480418EEE056C648530BFEEA
/* window.elastic = axios.create({
	baseURL: 'https://api.elasticemail.com/v4',
	headers: { common: { 'X-ElasticEmail-ApiKey': 'BAC37FCDBA4217467B45C748CD4AC53E8FAAF484AE908B20FAF683B41C074DA878DE9603480418EEE056C648530BFEEA' } }
}); */
/* const mailchimp = require('@mailchimp/mailchimp_transactional')('md-Cj0sGAsyxMOYwEichFjvLg');
// test2
// test-3 d7123eb034c97b75859eadc1c574d736-us18

mailchimp.messages.send({
	message: {
		to: [{ email: 'support@midadworld.com', type: 'to' }],
		subject: 'Hello World',
		text: 'Welcome to Mailchimp Transactional!',
		from_email: 'tester2@merepost.com'
	}
})
mailchimp.senders.addDomain({
	domain: 'midadworld.com'
})
 */
/* Elastic Emails
// tester2@merepost.com
// 677A19F283596AB8BCC698A6129A75B38066C920FD5B72C3C4AEB500DA618B8F4B92F685F47D2C3D6D4904D0F8AC8517
/* window.elastic2 = axios.create({
	baseURL: 'https://api.elasticemail.com/v4',
	headers: { common: { 'X-ElasticEmail-ApiKey': '677A19F283596AB8BCC698A6129A75B38066C920FD5B72C3C4AEB500DA618B8F4B92F685F47D2C3D6D4904D0F8AC8517' } }
}); */
/* window.elastic2.post('/emails', {
	Recipients: [{ Email: 'tester2@merepost.com' }],
	Content: {
		TemplateName: 'test_temp',
		From: 'tester2 <tester2@merepost.com>',
		Merge: { firstname: 'MOS' },
		ReplyTo: 'tester2 <tester2@merepost.com>',
		Subject: 'Hello!',
	}
}) */

// SANAD
// 8A4CBC98F4FB0E2B29C7F61906E8F80F56D558226284E582CC7BDBD321D5F8FB5A2D9A2A5BBBDE5B5B9AC594567DBE11
/* const sanad = axios.create({
	baseURL: 'https://api.elasticemail.com/v4',
	headers: {
		common: {
			'X-ElasticEmail-ApiKey': '8A4CBC98F4FB0E2B29C7F61906E8F80F56D558226284E582CC7BDBD321D5F8FB5A2D9A2A5BBBDE5B5B9AC594567DBE11'
		}
	}
})
window.sanad = sanad; */
/* const sanad = axios.create({
	baseURL: 'https://api.elasticemail.com/v4',
	headers: {
		common: {
			'X-ElasticEmail-ApiKey': '8A4CBC98F4FB0E2B29C7F61906E8F80F56D558226284E582CC7BDBD321D5F8FB5A2D9A2A5BBBDE5B5B9AC594567DBE11'
		}
	}
})
// abandoned cart 1 
sanad.post('/emails', {
	Recipients: [{ Email: 'receiver@merepost.com', Fields: { firstname: 'receiver' } }],
	Content: {
		TemplateName: 'abandoned_cart_2_hours_raw',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear() },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'لا تفوتك منتجاتك المُختارة في السلة يا {firstname}!',
	},
	Options: {
		TimeOffset: 60 * 2, // 2 hours
	}
})

// abandoned cart 2
sanad.post('/emails', {
	Recipients: [{ Email: 'receiver@merepost.com' }],
	Content: {
		TemplateName: 'abandoned_cart_2_days',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear() },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'فرصتك الأخيرة لا تفوّتها!',
	},
	Options: {
		TimeOffset: 60 * 24 * 2, // 2 days
	}
})

// after purchase
// after purchase 1 
sanad.post('/emails', {
	Recipients: [{ Email: 'receiver@merepost.com' }],
	Content: {
		TemplateName: 'after_purchase_immediate',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear() },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'نتمنى تكون تجربتك معنا مميزة! - شاركنا رأيك 🌟',
	}
})

// after purchase 2 
sanad.post('/emails', {
	Recipients: [{ Email: 'receiver@merepost.com' }],
	Content: {
		TemplateName: 'after_purchase_1_week',
		Merge: { url: 'https://sanad.beetronix.net', firstname: 'receiver', year: new Date().getFullYear() },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'اكتشف الجديد! - منتجات قد تكون الإضافة الكاملة لمشروعك 🛍️',
	},
	Options: {
		TimeOffset: 60 * 24 * 7, // 1 week
	}
})

// after purchase 3
sanad.post('/emails', {
	Recipients: [{ Email: 'receiver@merepost.com' }],
	Content: {
		TemplateName: 'after_purchase_2_weeks',
		Merge: { url: 'https://sanad.beetronix.net', firstname: 'receiver', year: new Date().getFullYear() },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'استفد من النقاط برصيدك! - تسوق الآن واكسب أكثر 💰',
	},
	Options: {
		TimeOffset: 60 * 24 * 7 * 2, // 2 week
	}
})

// loyalty points
sanad.post('/emails', {
	Recipients: [{ Email: 'receiver@merepost.com' }],
	Content: {
		TemplateName: 'loyalty_points_1_month_remaining',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear() },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'باقي شهر للاستمتاع بنقاط الكاش باك 🚨',
	}
})

sanad.post('/emails', {
	Recipients: [{ Email: 'receiver@merepost.com' }],
	Content: {
		TemplateName: 'loyalty_points_1_week_remaining',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear() },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'تذكير: نقاطك على وشك تختفي! ⏳️',
	}
})
*/
/* 
function getTemplate(templateName, merge) {
	sanad.get(`/templates/${templateName}`).then(({ data }) => {
		let html = data.Body[0].Content;
		Object.keys(merge).forEach(key => {
			/* eslint-disable no-useless-escape //
			const regexp = new RegExp(`\{${key}\}`, 'g')
			html = html.replace(regexp, merge[key]);
		})
		console.log(html);
	})
}

window.getTemplate = getTemplate; */
/* 
// abandoned cart 1 
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com', Fields: { firstname: 'جورج' } }],
	Content: {
		TemplateName: 'abandoned_cart_2_hours_raw',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'لا تفوتك منتجاتك المُختارة في السلة يا {firstname}!',
	}
})

// abandoned cart 2
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'abandoned_cart_2_days_raw',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'فرصتك الأخيرة لا تفوّتها!',
	}
})

// after purchase
// after purchase 1 
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'after_purchase_immediate_raw',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear(), preheader: 'شاركنا رأيك 🌟' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: ' نتمنى تكون تجربتك معنا مميزة! ',
	}
})

// after purchase 2 
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'after_purchase_1_week_raw',
		Merge: { url: 'https://sanad.beetronix.net', firstname: 'جورج', year: new Date().getFullYear(), preheader: 'منتجات قد تكون الإضافة الكاملة لمشروعك 🛍️' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'اكتشف الجديد! ',
	}
})

// after purchase 3
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'after_purchase_2_weeks_raw',
		Merge: { url: 'https://sanad.beetronix.net', firstname: 'جورج', year: new Date().getFullYear(), preheader: 'تسوق الآن واكسب أكثر 💰' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'استفد من النقاط برصيدك!',
	}
})

// loyalty points
sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'loyalty_points_1_month_remaining_raw',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'باقي شهر للاستمتاع بنقاط الكاش باك 🚨',
	}
})

sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'loyalty_points_1_week_remaining_raw',
		Merge: { url: 'https://sanad.beetronix.net', year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'تذكير: نقاطك على وشك تختفي! ⏳️',
	}
})

sanad.post('/emails', {
	Recipients: [{ Email: 'jourge.escalejyan@gmail.com' }],
	Content: {
		TemplateName: 'customized_content',
		Merge: { content: ``, year: new Date().getFullYear(), preheader: '' },
		From: 'SANAD <noreply@sanadagency.com>',
		Subject: 'تذكير: نقاطك على وشك تختفي! ⏳️',
	}
})
*/