var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"editorWrapper",style:(`--ck-content-height: ${_vm.height}px`)},[_c('v-dialog',{attrs:{"max-width":"350","content-class":"rounded-medium"},model:{value:(_vm.insertImageDialog.dialog),callback:function ($$v) {_vm.$set(_vm.insertImageDialog, "dialog", $$v)},expression:"insertImageDialog.dialog"}},[_c('v-form',{ref:"insertImageForm",on:{"submit":function($event){$event.preventDefault();_vm.editor.execute('insertImage', {
					source: [{
						src: _vm.insertImageDialog.imageUrl,
						width: _vm.insertImageDialog.width,
						height: _vm.insertImageDialog.height
					}]
				});
				_vm.editor.execute('imageStyle', { value: 'inline' });
				_vm.insertImageDialog.dialog = false;}}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center text-uppercase text-h6"},[_vm._v(" Insert image ")]),_c('v-card-text',{staticClass:"pb-1"},[_c('v-row',{attrs:{"dense":"","justify":"center","align":"center"}},[(_vm.insertImageDialog.imageUrl)?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-img',{staticClass:"rounded-medium mx-auto mb-3",attrs:{"src":_vm.insertImageDialog.imageUrl,"lazy-src":require("@/assets/blurred.png"),"max-width":"200","width":"200"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attr, on }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"absolute":"","rounded":"","min-width":"0","width":"35","height":"35","color":"primary"},on:{"click":_vm.activateFileManager}},'v-btn',attr,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-rotate-3d-variant")])],1)]}}],null,false,2323496646)},[_vm._v(" "+_vm._s(_vm.$t('tooltip.replace-the-image'))+" ")])],1):_vm._e(),_c('v-col',{attrs:{"sm":"5","cols":"12"}},[_c('v-text-field',{staticClass:"rounded-medium",attrs:{"label":"width","type":"number","hide-spin-buttons":"","dense":"","outlined":"","hide-details":""},on:{"input":function($event){_vm.insertImageDialog.retainRatio
										? _vm.insertImageDialog.height =
											_vm.fixNumber(_vm.insertImageDialog.width / _vm.insertImageDialog.ratio, 3)
										: null}},model:{value:(_vm.insertImageDialog.width),callback:function ($$v) {_vm.$set(_vm.insertImageDialog, "width", $$v)},expression:"insertImageDialog.width"}})],1),_c('v-col',{staticClass:"d-flex justify-center pa-sm-0",attrs:{"sm":"1","cols":"12"}},[_c('v-btn',{attrs:{"min-width":"0","width":"30","height":"30","icon":"","color":_vm.insertImageDialog.retainRatio ? 'primary' : null},on:{"click":function($event){_vm.insertImageDialog.retainRatio = !_vm.insertImageDialog.retainRatio}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.insertImageDialog.retainRatio ? 'mdi-link' : 'mdi-link-off')+" ")])],1)],1),_c('v-col',{attrs:{"sm":"5","cols":"12"}},[_c('v-text-field',{staticClass:"rounded-medium",attrs:{"label":"height","type":"number","hide-spin-buttons":"","dense":"","outlined":"","hide-details":""},on:{"input":function($event){_vm.insertImageDialog.retainRatio
										? _vm.insertImageDialog.width =
											_vm.fixNumber(_vm.insertImageDialog.height * _vm.insertImageDialog.ratio, 3)
										: null}},model:{value:(_vm.insertImageDialog.height),callback:function ($$v) {_vm.$set(_vm.insertImageDialog, "height", $$v)},expression:"insertImageDialog.height"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-small",attrs:{"type":"submit","color":"primary"}},[_vm._v(_vm._s(_vm.$t('confirm')))]),_c('v-btn',{staticClass:"rounded-small",on:{"click":function($event){_vm.insertImageDialog.dialog = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))])],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"900"},model:{value:(_vm.previewDialog),callback:function ($$v) {_vm.previewDialog=$$v},expression:"previewDialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-4"},[_c('div',{staticClass:"ck-content",domProps:{"innerHTML":_vm._s(_vm.localValue)}})]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"rounded-medium",attrs:{"color":"primary"},on:{"click":function($event){_vm.previewDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])],1)],1)],1),_c('editor',{class:{ 'validate-color': _vm.error },attrs:{"editor":_vm.editorType,"config":_vm.editorConfig,"disabled":_vm.disabled},on:{"ready":function($event){return _vm.$emit('ready', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"destroy":function($event){return _vm.$emit('destroy', $event)}},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}}),(_vm.loading)?_c('v-progress-linear',{staticClass:"editor-progress",attrs:{"indeterminate":""}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }