import { useRestore } from '@/configs/global.config';
import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		products: []
	},

	actions: {
		fetchAll({ commit }, { useOrder }) {
			return axios.get('/products', {
				params: { useOrder }
			}).then(({ data }) => {
				const storeData = data.filter(c => !useRestore.products ? !c.deletedAt : true);
				commit(types.STORE_PRODUCTS, storeData);
				return storeData;
			})
		},
		fetchAllByCategory({ commit }, { useOrder, categorySlug }) {
			return axios.get(`/products/${categorySlug}/categories`, {
				params: { useOrder }
			}).then(({ data }) => {
				return data.filter(c => !useRestore.products ? !c.deletedAt : true)
			})
		},
		fetchBySlug({ commit }, { slug, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.get(`/products/${slug}`, configs).then(({ data }) => data)
		},

		create({ commit }, {
			name, summary, code, isFeatured, inHomePage, isQuantitative, suggestRelated, price, secondPrice, dateOfPublication, description, categoryIds, filterIds, keywordIds, publisherIds, attributes, relatedProductIds, upSellingTitle, descriptionSeo, keywordsSeo, image, imagePath, imageName, filePath, fileName, acceptLanguage
		}) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { name, summary, code, price, secondPrice, dateOfPublication, description, upSellingTitle, descriptionSeo, keywordsSeo, image, imageName, filePath, fileName };

			Object.keys(requestData).forEach(key => {
				if (requestData[key] || typeof requestData[key] === 'number') {
					formData.append(key, requestData[key]);
				}
			});
			
			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));

			formData.append('isFeatured', isFeatured ? 1 : 0);
			formData.append('inHomePage', inHomePage ? 1 : 0);
			formData.append('isQuantitative', isQuantitative ? 1 : 0);
			formData.append('suggestRelated', suggestRelated ? 1 : 0);

			if (categoryIds) {
				categoryIds.forEach(id => {
					formData.append('categoryIds[]', id);
				});
			}
			if (filterIds) {
				filterIds.forEach(id => {
					formData.append('filterIds[]', id);
				});
			}
			if (keywordIds) {
				keywordIds.forEach(id => {
					formData.append('keywordIds[]', id);
				});
			}
			if (publisherIds) {
				publisherIds.forEach(id => {
					formData.append('publisherIds[]', id);
				});
			}
			if (Array.isArray(attributes)) {
				attributes.forEach(({ id, value }, i) => {
					if (id && value) {
						formData.append(`attributes[${i}][id]`, id);
						formData.append(`attributes[${i}][value]`, value);
					}
				});
			}
			if (Array.isArray(relatedProductIds)) {
				relatedProductIds.forEach(id => {
					formData.append('relatedProductIds[]', id);
				});
			}

			return axios.post('/products', formData, configs).then(({ data }) => data)
		},

		update({ commit }, { 
			id, name, summary, code, isFeatured, inHomePage, isQuantitative, suggestRelated, price, secondPrice, dateOfPublication, description, categoryIds, filterIds, keywordIds, publisherIds, attributes, relatedProductIds, upSellingTitle, descriptionSeo, keywordsSeo, image, imagePath, imageName, filePath, fileName, acceptLanguage
		}) {
			const configs = headerAcceptLanguage(acceptLanguage);

			const formData = new FormData();
			const requestData = { name, summary, code, price, secondPrice, dateOfPublication, description, upSellingTitle, descriptionSeo, keywordsSeo, image, imageName, filePath, fileName };

			Object.keys(requestData).forEach(key => {
				if (requestData[key] || typeof requestData[key] === 'number') {
					formData.append(key, requestData[key]);
				}
			});

			if (imagePath) formData.append('imagePath', imagePath.slice(imagePath.indexOf('/', 1)));
			
			formData.append('isFeatured', isFeatured ? 1 : 0);
			formData.append('inHomePage', inHomePage ? 1 : 0);
			formData.append('isQuantitative', isQuantitative ? 1 : 0);
			formData.append('suggestRelated', suggestRelated ? 1 : 0);

			if (categoryIds) {
				categoryIds.forEach(id => {
					formData.append('categoryIds[]', id);
				});
			}
			if (filterIds) {
				filterIds.forEach(id => {
					formData.append('filterIds[]', id);
				});
			}
			if (keywordIds) {
				keywordIds.forEach(id => {
					formData.append('keywordIds[]', id);
				});
			}
			if (publisherIds) {
				publisherIds.forEach(id => {
					formData.append('publisherIds[]', id);
				});
			}
			if (Array.isArray(attributes)) {
				attributes.forEach(({ id, value }, i) => {
					if (id && value) {
						formData.append(`attributes[${i}][id]`, id);
						formData.append(`attributes[${i}][value]`, value);
					}
				});
			}
			if (Array.isArray(relatedProductIds)) {
				relatedProductIds.forEach(id => {
					formData.append('relatedProductIds[]', id);
				});
			}
			
			formData.append('_method', 'PUT');

			return axios.post(`/products/${id}`, formData, configs).then(({ data }) => data)
			// headers: { 'content-type': 'multipart/form-data' } 
		},

		uploadImages({ commit }, { id, images, imagesPaths, imagesNames }) {
			const formData = new FormData();
			images.forEach(image => {
				formData.append('images[]', image);
			});
			imagesPaths.forEach(path => {
				formData.append('imagesPaths[]', path.slice(path.indexOf('/', 1)));
			});
			imagesNames.forEach(name => {
				formData.append('imagesNames[]', name);
			});

			return axios.post(`/products/${id}/add-images`, formData)
		},

		deleteImage({ commit }, { id, imageId }) {
			return axios.post(`/products/${id}/images/${imageId}`, { _method: 'DELETE' })
		},

		changeState({ commit }, { id }) {
			return axios.post(`/products/${id}/change-status`, { _method: 'PUT' });
		},
		
		changeOrder({ commit }, { id, order }) {
			return axios.post(`/products/${id}/change-order`, { num: order, _method: 'PUT' });
		},

		delete({ commit }, { id }) {
			return axios.post(`/products/${id}`, { _method: 'DELETE' })
		},
		restore({ commit }, { id }) {
			return axios.post(`/products/${id}`, { _method: 'PATCH' })
		},

		// up selling
		createUpSelling({ commit }, { productId, text, cartTitle, type, price, optionTitle, options, isRequired, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post('/up-selling', {
				productId,
				text,
				cartTitle,
				type,
				price,
				optionTitle,
				options,
				isRequired: isRequired ? 1 : 0,
			}, configs).then(({ data }) => data);
		},
		updateUpSelling({ commit }, { id, productId, text, cartTitle, type, price, optionTitle, options, isRequired, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post(`/up-selling/${id}`, {
				productId,
				text,
				cartTitle,
				type,
				price,
				optionTitle,
				options,
				isRequired: isRequired ? 1 : 0,
				_method: 'PUT'
			}, configs).then(({ data }) => data);
		},
		deleteUpSelling({ commit }, { id }) {
			return axios.delete(`/up-selling/${id}`)
		},

		// offers
		updateProductOffers({ commit }, { id, offerIds }) {
			const formData = new FormData();

			offerIds.forEach(id => {
				formData.append('offerIds[]', id);
			})

			formData.append('_method', 'PUT');
			
			return axios.post(`/products/${id}/add-to-offers`, formData);
		}
	},

	mutations: {
		[types.STORE_PRODUCTS](state, products) {
			state.products = products;
		}
	},

	getters: {
		getProductById: state => id => state.products.find(c => c.id === id),
		getProductBySlug: state => slug => state.products.find(c => c.slug === slug),
	}
}