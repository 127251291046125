import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		systemUsers: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/users').then(({ data }) => {
				const storeData = data.filter(c => c.isAdmin);
				commit(types.STORE_SYSTEM_USERS, storeData);
				return storeData;
			})
		},

		fetchById({ commit }, { id }) {
			return axios.get(`/users/${id}`).then(({ data }) => data)
		},
		
		create({ commit }, { fullName, userName, email, address, phone, password, passwordConfirmation, setId }) {
			return axios.post('/users/0', { fullName, userName, email, address, phone, password, passwordConfirmation, setId })
		},

		update({ commit }, { id, fullName, userName, email, address, phone, password, passwordConfirmation, setId }) {
			return axios.post(`/users/${id}`, { fullName, userName, email, address, phone, password, passwordConfirmation, setId, _method: 'PUT' })
		},

		changeState({ commit }, { id }) {
			return axios.post(`/users/${id}/change-status`, { _method: 'PUT' });
		},
	},

	mutations: {
		[types.STORE_SYSTEM_USERS](state, systemUsers) {
			state.systemUsers = systemUsers;
		}
	},

	getters: {
		getSystemUserById: state => id => state.systemUsers.find(c => c.id === id)
	}
}