import { products } from '@/configs/translates.config';

export default {
	'add-new-product': `add new ${products.en.singular}`,
	'the-product': `the ${products.en.singular}?`,
	product: products.en.singular,
	confirm: 'confirm',
	'are-you-sure-you-want-to-$-this-product': `Are you sure you want to {state} this ${products.en.singular}?`,
	'up-selling-form': 'up-selling form',
	entry: 'entry',
	options: 'options',
	'in-cart': 'in cart',
	'offer-data': 'offer data',
	required: 'required'
}