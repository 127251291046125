<template>
	<div class="audio-player" :style="'--plyr-color-main:' + color">
		<!-- data-poster="poster.jpg" -->
		<audio controls ref="audio">
			<source :src="src" type="audio/mp3"/>
		</audio >

		<p v-if="label" class="mb-0 text-center font-weight-bold">{{label}}</p>
		
		<div class="audio-player__actions">
			<v-btn
				v-if="showSelect && !showCancel"
				height="32"
				width="32"
				min-width="20"
				color="success"
				class="rounded-xl px-2 me-4"
				@click="$emit('select', src)"
			>
				<v-icon size="22" color="white">mdi-check</v-icon>
			</v-btn>

			<v-btn
				v-if="showCancel"
				height="30"
				width="30"
				min-width="20"
				color="grey darken-3 white--text"
				class="image-card__action rounded-xl px-2 me-4"
				@click="$emit('cancel', src)"
			>
				<v-icon size="22">mdi-close</v-icon>
			</v-btn>

			<v-btn
				v-if="showDelete"
				height="32"
				width="32"
				min-width="20"
				color="red lighten-1"
				class="rounded-xl px-2"
				@click="$emit('remove', src)"
			>
				<v-icon size="22" color="white">mdi-delete</v-icon>
			</v-btn>
		</div>
	</div>
</template>

<script>
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
import { mainColor } from '@/configs/brand.config';
export default {
	name: 'AudioPlayer',
	
	props: {
		src: { type: String },
		color: { type: String, default: mainColor },
		label: { type: String },
		mainControls: { type: Boolean, default: false },
		showDelete: { type: Boolean, default: false },
		showCancel: { type: Boolean, default: false },
		showSelect: { type: Boolean, default: false },
	},

	data: () => ({
		player: null,
	}),

	watch: {
		src(newVal) {
			if (newVal) {
				this.$refs.audio.load();
			}
		}
	},

	computed: {
		options() {
			let controls = [
				'play-large',
				'play',
				'progress',
				'current-time',
				'mute',
				'captions',
				'settings',
				'pip',
				'airplay',
				'fullscreen'
			];
			if (this.mainControls) {
				controls = [
					'play',
					'progress',
					'current-time',
					'fullscreen'
				]
			}
			return {
				controls,
				speed: { selected: 1, options: [0.5, 1, 1.5] }
			}
		}
	},

	updated() {
		this.player = new Plyr(this.$refs.audio, this.options);
	},

	mounted() {
		this.player = new Plyr(this.$refs.audio, this.options);
	},

	beforeDestroy() {
		this.player.pip = false;
		this.player.stop();
	}
}
</script>

<style lang="scss">
.audio-player {
	position: relative;

	.plyr {
		border-radius: 11px;
	}
	
	&__actions {
		opacity: 0;
		visibility: hidden;

		transition: all .3s;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover &__actions {
		opacity: 1;
		visibility: visible;
	}
}
</style>