import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		users: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/users').then(({ data }) => {
				const storeData = data.filter(c => !c.isAdmin);
				commit(types.STORE_USERS, storeData);
				return storeData;
			})
		},
		fetchById({ commit }, { id }) {
			return axios.get(`/users/${id}`).then(({ data }) => data)
		},
		fetchUserStatics({ commit }, { id, fromDate, toDate }) {
			return axios.get('/statics-user', {
				params: { userId: id, startDate: fromDate, endDate: toDate }
			}).then(({ data }) => data)
		},
		changeState({ commit }, { id }) {
			return axios.post(`/users/${id}/change-status`, { _method: 'PUT' });
		},

		create({ commit }, { fullName, userName, email, address, phone, password, passwordConfirmation }) {
			return axios.post('/users/0', { admin: 0, fullName, userName, email, address, phone, password, passwordConfirmation })
		},

		update({ commit }, { id, fullName, userName, email, address, phone, password, passwordConfirmation }) {
			return axios.post(`/users/${id}`, { admin: 0, fullName, userName, email, address, phone, password, passwordConfirmation, _method: 'PUT' })
		},
	},

	mutations: {
		[types.STORE_USERS](state, users) {
			state.users = users;
		}
	},

	getters: {
		getUserById: state => id => state.users.find(c => c.id === id)
	}
}