import { headerAcceptLanguage } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		ratings: [],
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/ratings/rating-comments').then(({ data }) => {
				commit('STORE_RATINGS', data)
				return data;
			})
		},

		createRating({ commit }, { userId, productId, stars }) {
			return axios.post('/ratings', { userId, productId, stars });
		},

		createComment({ commit }, { userId, productId, comment, date, userName, email, acceptLanguage }) {
			const configs = headerAcceptLanguage(acceptLanguage);
			return axios.post('/products/comment', { userId, productId, comment, date, userName, email }, configs);
		},

		async delete({ commit }, { ratingId, commentId }) {
			await (ratingId ? axios.post(`/ratings/${ratingId}`, { _method: 'DELETE' }) : null);
			await (commentId ? axios.post(`/comments/${commentId}`, { _method: 'DELETE' }) : null);
		}
	},

	mutations: {
		[types.STORE_RATINGS](state, ratings) {
			state.ratings = ratings;
		}
	}
}